import InputMask from 'inputmask';
import {FULLNAME_PATTERN} from '../upi/constants'

const MASK_DEFAULTS = {
    placeholder: '_',
    greedy: false,
    autoUnmask: true,
    removeMaskOnSubmit: true,
    clearMaskOnLostFocus: true,
    skipOptionalPartCharacter: true
};
/**
 * Custom aliases that extend available masks for InputMask util
 */
const phoneAU = {
    ...MASK_DEFAULTS,
    // mask options are ordered by relevance
    mask: '(ZL #### ####)|((ZL) #### ####)|(ZM## ### ###)|(+61### ### ###)',
    definitions: {
        '#': {
            validator: '[0-9]',
            cardinality: 1
        },
        'Z': {
            validator: '0',
            cardinality: 1
        },
        'M': {
            validator: '4',
            cardinality: 1
        },
        'L': {
            validator: '(2|3|7|8)',
            cardinality: 1
        }
    }
};

const phoneUS = {
    ...MASK_DEFAULTS,
    // mask options are ordered by relevance
    mask: '(Z##) Z##-####',

    definitions: {
        '#': {
            validator: '[0-9]',
            cardinality: 1
        },
        'Z': {
            validator: '[2-9]',
            cardinality: 1
        }
    }
};

// Extend the InputMask library with custom extensions then export a single instance to use in upi.namespace['forms']
$.extend( InputMask.prototype.aliases, {phoneUS, phoneAU} );

export default InputMask;

