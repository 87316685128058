import assign from 'object-assign';

var upi = assign( {}, window.upi || {}, {

    noop: function () {},

    namespace: function ( ns_str, scope ) {
        var parts = ns_str.split( '.' ), parent = scope || window.upi;

        // filter redundant ns global
        if ( parts[i] === 'upi' ) {
            parts = parts.slice( 1 );
        }

        for ( var i = 0, l = parts.length; i < l; i++ ) {
            // create ns if not exists
            if ( typeof parent[parts[i]] === 'undefined' ) {
                parent[parts[i]] = {};
            }
            parent = parent[parts[i]];
        }

        return parent;
    }
} );

window.upi = assign( window.upi || {}, upi );

