import FindADentist from '@services/FindADentistService';
import {COOKIES, getCookie, setCookie} from '@util/StorageUtils';
import countryNameMap from '@util/countryNameMap';

var markersArray = [];
var infos = [];

const gMap = {
    init ( scope ) {

        this.userLocation;
        this.countryName = countryNameMap[document.documentElement.getAttribute( 'lang' )] || null;
        // note -> Change map variable to something like googleMap or mapApi
        this.map = null;
        this.updateAttempts = 0;
        this.smartZoomCount = 0;
        this.oms = null;
        this.hasAcceptedTerms = getCookie( COOKIES.FIND_A_DENTIST );

        this.promptTerms = this.promptTerms.bind( this );
        this.updateDentistsOnMap = this.updateDentistsOnMap.bind( this )

        // Initialize a new Google Map Object
        this.map = new google.maps.Map( document.getElementById( 'map' ), {
            // center: {lat: 40.7608, lng: -111.8910},
            zoom: 4,
            disableDefaultUI: true,
            fullscreenControl: true,
            styles: [
                {
                    featureType: 'poi',
                    stylers: [{visibility: 'off'}]
                }
            ]
        } );

        google.maps.Map.prototype.clearOverlays = function () {
            for ( var i = 0; i < markersArray.length; i++ ) {
                markersArray[i].setMap( null );
            }
            markersArray.length = 0;
        };

        this.loadOMS();

        // Create the custom zoom controls
        var zoomControlsDiv = document.createElement( 'div' );
        zoomControlsDiv.style.display = 'flex';
        zoomControlsDiv.style.justifyContent = 'space-around';
        zoomControlsDiv.style.alignContent = 'center';
        zoomControlsDiv.style.marginRight = '25px';
        zoomControlsDiv.style.marginBottom = '25px';

        this.DecreaseZoomControl( zoomControlsDiv, this.map );
        this.IncreaseZoomControl( zoomControlsDiv, this.map );

        this.map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push( zoomControlsDiv );
        this.geocoder = new google.maps.Geocoder();
        // Initialize the Marker, InfoWindow, and geoCoder Objects.
        this.gMapInfoWindow = new google.maps.InfoWindow;

        if ( !this.hasAcceptedTerms ) {
            $( '#address' ).on( 'focus', this.promptTerms );
        }
        else {
            $( '#map-placeholder' ).show();
            this.initializeMap( $( '#page-container' ) );
        }
    },

    initOms () {
        this.oms = new OverlappingMarkerSpiderfier( this.map, {
            markersWontMove: true,
            markersWontHide: true,
            basicFormatEvents: true
        } );
    },

    loadOMS () {
        window.upi.loadScriptAsync(
            'https://cdnjs.cloudflare.com/ajax/libs/OverlappingMarkerSpiderfier/1.0.3/oms.min.js',
            {
                id: 'omsScript',
                onLoad: this.initOms.bind( this )
            } )
    },

    initializeMap ( scope, userLocation ) {
        const self = this;
        scope.find( '#map-container' ).each( ( index, el ) => {

            // $( '#inner-map-container' ).removeClass( 'hidden' );

            this.googleEventListener( 'dragend', self.map );
            this.googleEventListener( 'zoom_changed', self.map );

            this.getFromUserGeolocation( this.gMapInfoWindow, this.map );

            $( '#submit' ).on( 'click', function () {
                self.geoCodeAddress( self.geocoder, self.map, $( '#address' ).val() );
            } );
            $( '#address' ).on( 'keypress', function ( e ) {
                if ( e.which === 13 ) {
                    self.geoCodeAddress( self.geocoder, self.map, $( '#address' ).val() );
                }
            } );

        } );
    },

    promptTerms () {
        $( '#map-placeholder' ).hide();
        $( '#fADTerms' ).removeClass( 'hidden' );
        document.getElementById( 'agreeTerms' ).addEventListener( 'click', this.acceptTerms.bind( this ) );
        document.getElementById( 'backToMap' ).addEventListener( 'click', this.cancelTerms.bind( this ) );
    },

    acceptTerms () {
        setCookie( {
            cookie: COOKIES.FIND_A_DENTIST,
            key: {name: 'acceptTerms'},
            value: 'yes'
        } );

        $( '#address' ).off( 'focus', this.promptTerms );
        $( '#fADTerms' ).fadeOut( 'fast' );
        $( '#map-placeholder' ).show();
        this.initializeMap( $( '#page-container' ) );
    },

    cancelTerms () {
        $( '#map-placeholder' ).fadeIn( 'fast' );
        $( '#fADTerms' ).addClass( 'hidden' );
    },

    getMapBounds ( map ) {
        var bounds = map.getBounds();

        var NE = bounds.getNorthEast();
        var SW = bounds.getSouthWest();
        return {
            north: NE.lat(),
            east: NE.lng(),
            south: SW.lat(),
            west: SW.lng()
        };
    },

    showResults () {
        $( '#map-placeholder' ).fadeOut( 'fast' );
        $( '#inner-map-container' ).removeClass( 'hidden' );
    },

    googleEventListener ( eventName, map ) {
        map.addListener( eventName, this.updateDentistsOnMap );
    },

    handleMapBoundsError ( err ) {
        this.updateAttempts++;
        console.warn( '[GoogleMap Error] map.getBounds not accessible, trying again...', err );

        if ( this.updateAttempts <= 5 ) {
            setTimeout( this.updateDentistsOnMap, 200 );
        }
        else {
            setTimeout( () => {
                this.updateAttempts = 0;
            }, 2000 );
        }
    },

    updateDentistsOnMap () {
        this.map.clearOverlays();
        let userLocation = null;

        try {
            userLocation = this.getMapBounds( this.map );
        }
        catch ( err ) {
            this.handleMapBoundsError( err );
            return;
        }

        this.updateAttempts = 0;

        $( '#loading' ).removeClass( 'hidden' );
        var self = this;

        FindADentist.getDentists( userLocation )
            .then( ( {data} ) => {

                if ( data.length < 5 && self.smartZoomCount < 2 ) {
                    self.smartZoomCount++;
                    self.map.setZoom( self.map.zoom - 1 );
                }

                if ( data.length === 51 ) {
                    $( '#filter-warning' ).fadeIn();
                }
                else {
                    $( '#filter-warning' ).fadeOut();
                }

                $( '#loading' ).addClass( 'hidden' );

                $( '#map-container' ).addClass( 'loading-dentist' );

                const isTouchevents = Modernizr.touchevents;

                for ( let dentist of data ) {
                    const {
                        name,
                        address1,
                        address2,
                        city,
                        state,
                        zipCode,
                        phoneNumber,
                        productsOffered,
                        latitude,
                        longitude
                    } = dentist;

                    var dentistHTML = '';

                    if ( upi.conf.features.ALLOW_PRODUCTS_FIND_DENTIST ) {
                        dentistHTML += `${self.productsOfferedHTML( productsOffered )}`;
                    }

                    dentistHTML += `
                        <h5 style="margin: 0.5rem 0;">${name}</h5>
                        <h6>${address1}, ${address2}</h6>
                        <h6>${city}, ${state} ${zipCode}</h6>
                        <h6><a href="tel:${phoneNumber}">${phoneNumber}</a></h6>
                    `;

                    var dentistInfoWindow = new google.maps.InfoWindow( {
                        content: dentistHTML
                    } );

                    var iconImage = {
                        url: '/images/find-a-dentist/mapMarker.svg',
                        optimized: false,
                        scaledSize: new google.maps.Size( 28, 28 )
                    };

                    var marker = new google.maps.Marker( {
                        position: {lat: latitude, lng: longitude},
                        title: name,
                        icon: iconImage,
                        clickable: true
                    } );

                    if ( isTouchevents ) {
                        google.maps.event.addListener( marker, 'spider_click',
                            (function ( infoWindow, content, marker ) {
                                return function () {
                                    self.closeInfos();

                                    infoWindow.setContent( content );
                                    infoWindow.open( self.map, marker );

                                    infos[0] = infoWindow;
                                }
                            })( dentistInfoWindow, dentistHTML, marker ) );
                    }
                    else {
                        google.maps.event.addListener( marker, 'mouseover',
                            (function ( infoWindow, content, marker ) {
                                return function () {
                                    if ( marker._omsData === undefined ) {
                                        google.maps.event.trigger( marker, 'click' );
                                    }
                                    self.closeInfos();

                                    infoWindow.setContent( content );
                                    infoWindow.open( self.map, marker );

                                    infos[0] = infoWindow;
                                }
                            })( dentistInfoWindow, dentistHTML, marker ) );
                    }

                    self.oms.addMarker( marker );
                    markersArray.push( marker );
                }
            } )
            .then( () => $( '#map-container' ).removeClass( 'loading-dentist' ) );
    },

    closeInfos () {
        if ( infos.length > 0 ) {
            infos[0].set( 'marker', null );

            infos[0].close();

            infos.length = 0;
        }
    },

    productsOfferedHTML ( products ) {
        const CONTACT_OFFICE_LABEL = upi.i18n.getTextFromKey(
            'lbl-contactOfficeAboutProductAvailability',
            'Contact office about product availability'
        );

        if ( !products || products.length <= 0 ) {
            return `<p class="mb-2 italic inline-block text-base max-w-xs">${CONTACT_OFFICE_LABEL}</p>`;
        }

        let HTML = '';

        $( products ).each( ( i, product ) => {
            switch ( product.toUpperCase() ) {
                case 'OPALESCENCE GO':
                    HTML += this.coloredBlockHTML( '#00a252' );
                    break;
                case 'OPALESCENCE PF':
                    HTML += this.coloredBlockHTML( '#00a3e0' );
                    break;
                case 'OPALESCENCE BOOST':
                    HTML += this.coloredBlockHTML( '#af272f' );
                    break;
                case 'SPECIALTY WHITENING':
                    HTML += this.coloredBlockHTML( '#762f96' );
                    break;
                case 'SENSITIVITY RELIEF GEL':
                    HTML += this.coloredBlockHTML( '#96be40' );
                    break;
                case 'OPALESCENCE WHITENING TOOTHPASTE':
                    HTML += this.coloredBlockHTML( '#00b2a9' );
                    break;
            }
        } );
        return HTML;
    },

    coloredBlockHTML ( color ) {
        return `
            <span style="display: inline-block; height: 1rem; width: 1rem; background-color: ${color}; margin-right: 0.5rem;"></span>
        `
    },

    getFromUserGeolocation ( infoWindow, map ) {
        if ( navigator.geolocation ) {

            // Get user position
            navigator.geolocation.getCurrentPosition(
                position => {
                    this.showResults();

                    var pos = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    };

                    map.setCenter( pos );
                    map.setZoom( 15 );
                }, () => {
                    const address = $( '#address' ).val();
                    // note -> If the user has already entered a value then ignore the request to block
                    if ( address.length > 1 ) {
                        console.log( 'Blocked location request' );
                        this.geoCodeAddress( this.geocoder, map, address );
                    }
                    else {
                        this.handleLocationError( true, infoWindow, map, map.getCenter() );
                    }
                } );
        }
        else {
            // Browser does not support Geolocation
            this.handleLocationError( false, infoWindow, map, map.getCenter() );
        }
    },

    geoCodeAddress ( geocoder, map, address ) {

        this.smartZoomCount = 0;

        // Append the country to the address if it's a postal code
        if ( /^\d+$/.test( address ) && this.countryName ) {
            address += ', ' + this.countryName;
        }

        geocoder.geocode( {'address': address}, ( results, status ) => {
            if ( status === 'OK' ) {
                this.showResults();

                // Coordinates returned is an object with Latitude, and Longitude of address provided by user.
                var {location} = results[0].geometry;

                this.map.clearOverlays();

                map.setCenter( location );
                map.setZoom( 15 );
            }
            else {
                alert( 'Geocode was not successful for the following reason: ' + status );
            }
        } );
    },

    handleLocationError ( browserHasGeoLocation, infoWindow, map, pos ) {
        infoWindow.setPosition( pos );
        infoWindow.setContent( browserHasGeoLocation ?
                               'Please enter an address to find dentists carrying Opalescence near you!' :
                               'Error: Your browser doesn\'t support geolocation.' );
        infoWindow.open( map );
    },

    IncreaseZoomControl ( controlDiv, map ) {
        var controlUI = document.createElement( 'div' );
        controlUI.classList.add( 'button', 'small' );
        controlUI.style.padding = '0.25rem 0.75rem';
        controlUI.style.marginLeft = '5px';
        controlUI.style.borderRadius = '0';
        controlDiv.appendChild( controlUI );

        var controlText = document.createElement( 'div' );
        controlText.style.fontWeight = 'bold';
        controlText.style.fontSize = '40px';
        controlText.innerHTML = '&plus;';
        controlUI.appendChild( controlText );

        controlUI.addEventListener( 'click', () => {
            map.setZoom( map.zoom + 1 );
        } );
    },

    DecreaseZoomControl ( controlDiv, map ) {
        var controlUI = document.createElement( 'div' );
        controlUI.classList.add( 'button', 'small' );
        controlUI.style.padding = '0.25rem 0.75rem';
        controlUI.style.marginRight = '5px';
        controlUI.style.borderRadius = '0';
        controlDiv.appendChild( controlUI );

        var controlText = document.createElement( 'div' );
        controlText.style.fontWeight = 'bold';
        controlText.style.fontSize = '40px';
        controlText.innerHTML = '&minus;';
        controlUI.appendChild( controlText );

        controlUI.addEventListener( 'click', () => {
            map.setZoom( map.zoom - 1 );
        } );
    }
};

export default gMap;
