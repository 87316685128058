const Videoplayer = {

    init ( scope ) {

        // Find element with the .video-responsive class in the #page-container element (scope)
        // SEE: upi/page-init.js in the media method
        scope.find( '.video-responsive' ).each( ( index, $el ) => {

            $( $el ).on( 'mousedown', () => {
                $el.querySelector( 'img' ).remove();
                $( '.play' ).remove();
                $( '.chevron-gradient' ).fadeTo( 1000, 0, function () {
                    $( this ).remove();
                } );
                $( $el ).on( 'mouseup', () => {
                    $( $el ).off( 'mousedown' );
                } );
                this.iframe( $el, $( $el ).attr( 'data-youtubeId' ) );
            } );

        } );
    },

    iframe: ( el, id ) => {
        let ytIframe = document.createElement( 'iframe' );
        ytIframe.setAttribute( 'src',
            `https://www.youtube.com/embed/${id}?rel=0&controls=1&autoplay=1` );

        el.append( ytIframe );
    }
};

export default Videoplayer;