'use strict';

import axios from 'axios';
import assign from 'object-assign';
import Events from '../util/events';

const conf = {
    SVC_ORIGIN: ''
};

const noop = () => {};

let services = {},
    supports = {
        CORS () {
            return 'withCredentials' in new XMLHttpRequest();
        }
    };

export default assign( {}, Events, {

    register ( servicePath, methods ) {
        if ( !servicePath ) {
            throw Error( 'ServiceProvider: "servicePath" cannot be undefined' );
        }

        services[servicePath] = assign( {}, services[servicePath], methods );

        let xhr = axios.create( {
            baseURL: conf.SVC_ORIGIN + servicePath,
            timeout: 20000,
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                'Cache-Control': 'no-cache', // Caching of Paymetric token caused cart to fail
                'Pragma': 'no-cache'
            }
        } );

        return {
            throw ( message ) {
                return new Promise( ( resolve, reject ) => {
                    console.error( message );
                    reject( Error( message ) );
                } );
            },
            request ( svcName, payload ) {
                // check if making CORS requests and ensure support
                if ( conf.SVC_ORIGIN.indexOf( window.location.host ) === -1 && !supports.CORS() ) {
                    return this.throw( 'CORS not fully supported' );
                }

                let url = servicePath + svcName,
                    data, params;
                // make sure valid service name provided
                if ( typeof svcName !== 'string' ) {
                    throw new TypeError( `${servicePath}: svcName is not a string, received "${svcName}" instead ` );
                }

                if ( !methods[svcName] ) {
                    throw new TypeError( `${servicePath}: service "${svcName}" not recognized in service list: ${Object.keys(
                        services[servicePath] )}` );
                }

                // return this._request( url, methods[ svcName ].method, payload ).request();
                let method = methods[svcName].method;

                if ( payload ) {
                    // check if it's a function
                    switch ( method ) {
                        case 'post':
                            // pass payload in request body
                            data = JSON.stringify( payload );
                            break;

                        default:
                            // pass payload as query string
                            // (default) method === GET
                            params = payload;
                    }
                }

                return xhr.request( svcName, {method, data, params} ).then( res => {
                    let result = res;
                    /*                    if ( result && result. === 'fail' ) {
                                            throw Error( result.Reason );
                                        }*/

                    return res;
                } )
            }
        };
    }
} );




