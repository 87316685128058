import '@modules/hotModule';
import '@css/style.scss';
import './upi/prototype';
import './upi/namespace';
import './upi/i18n';
import './upi/configuration';
import './upi/plugin-configuration';
import './upi/data-layer';
import './upi/forms';
import './upi/page-init';
import {name, version} from './upi/packageInfo';

console.log( `
   ____              _                                   
  / __ \\            | |                                  
 | |  | |_ __   __ _| | ___  ___  ___ ___ _ __   ___ ___ 
 | |  | | '_ \\ / _\` | |/ _ \\/ __|/ __/ _ \\ '_ \\ / __/ _ \\
 | |__| | |_) | (_| | |  __/\\__ \\ (_|  __/ | | | (_|  __/
  \\____/| .__/ \\__,_|_|\\___||___/\\___\\___|_| |_|\\___\\___|
        | |                                              
        |_|   
${name}@${version}                                           
` );