/**
 * usage:
 * modal.open({content: "<p>Howdy</p>"});
 * modal.open({content: $("<p>Howdy</p>"), width: "500px", height: "200px"});
 * // Ajax
 * $.get('ajax.html', function(data){
 *    modal.open({content: data});
 * });
 */

var $overlay,
    $modal,
    $content,
    $close,
    isOpen = false,
    preserve = false,
    is_animating = false,
    anim_end_event_names = {
        'WebkitAnimation': 'webkitAnimationEnd',
        'OAnimation': 'oAnimaitonEnd',
        'msAnimation': 'MSAnimationEnd',
        'animation': 'animationend'
    },
    anim_end_event_name = anim_end_event_names[Modernizr.prefixed( 'animation' )],
    anim_control_class = {
        fold: {
            inClass: 'fadeUp',
            outClass: 'fadeOut'
        },
        fade: {
            inClass: 'fadeUp',
            outClass: 'fadeOut'
        },
        slideTop: {
            inClass: 'slideDown',
            outClass: 'slideUp'
        }
    };

/* transition helpers */
function transition_el ( el, classList, fn ) {
    if ( is_animating ) {
        return false;
    }

    is_animating = true;

    el.addClass( classList ).on( anim_end_event_name, function () {
        fn && fn();
        el.off( anim_end_event_name );
        is_animating = false;
        reset_animation_state( el );
    } );
}

function reset_animation_state ( el ) {
    el.attr( 'class', el.data( 'origClassList' ) );
}

function pullDown ( el ) {
    var orgY = el.offset().top;

    el.css( {
        top: -el.height(),
        opacity: 0
    } ).animate( {
        top: orgY,
        opacity: 1
    }, 300 );

}

var center = function ( position ) {
    var top,
        left,
        position = position || 'center',
        contentOffset = {
            height: $content[0].offsetHeight,
            width: $content[0].offsetWidth
        },
        $window = $( window );

    if ( 'top' === position ) {
        top = 0;
    }
    else {
        top = Math.max( window.innerHeight - contentOffset.height, 0 ) / 2 - 86;
    }
    left = Math.max( $window.width() - 20 - contentOffset.width, 0 ) / 2;

    $content.css( {
        top: contentOffset.height < $window.height()
            ? top
            : 0,
        left: left
    } );
};

var setSize = function ( settings ) {
    var target = settings.target,
        width = settings.width,
        height = settings.height;

    $content.css( {
        width: '100%',
        'max-width': target && parseInt( target.attr( 'data-modal-width' ), 10 ) || width || 'auto',
        height: target && target.attr( 'data-modal-height' ) || height || 'auto'
    } );
};

var toggleOverlay = function ( doApply ) {
    $( 'html' ).toggleClass( 'blur-body', doApply );
    $( 'html' ).toggleClass( 'no-scroll', doApply );
};

var createFrame = function ( src ) {
    var ifrm = document.createElement( 'iframe' );
    ifrm.setAttribute( 'src', src || '/' );
    ifrm.style.width = '100%';
    ifrm.style.height = 'auto';

    return ifrm;
};

/**
 * Open modal window specifying either the html content to display or a node selector who's content
 * will be displayed within page
 *
 * @param {Object} settings [, target, content, className ,width, height ]
 */
var open = function ( settings ) {
    settings = settings || {};

    $modal.trigger( 'modal:beforeopen' );

    $content.off().empty();

    var target;
    // if provided a target id then pluck from DOM and set preserve flag
    if ( settings.target ) {
        preserve = true;
        target = settings.target = $( settings.target ).clone( true ).removeClass( 'visually-hidden hidden-lightbox' );
    }

    setContent( target || settings.content );
    setSize( settings );

    settings.className && $content.addClass( settings.className );
    $modal.show();
    setTimeout( () => center( settings.position ), 0 );
    toggleOverlay( true );

    // don't reposition modal for small devices OR "FIXED" modal option
    if ( Modernizr && Modernizr.mq( 'screen and (min-width: 30em)' ) ) {
        var _resizeHandler = center.bind( this, settings.position );
        $( window ).on( 'resize.modal', _resizeHandler );
        $modal.on( 'modal:closecomplete', function () {
            $( window ).off( 'resize.modal', _resizeHandler );
        } )
    }

    // only trigger transitions if modal is not already open
    if ( !isOpen ) {
        transition_el( $content, anim_control_class.fade.inClass );
    }
    $overlay.show();
    $modal.trigger( 'modal:opencomplete' );
    isOpen = true;
    return this;
};

var openTarget = function ( e ) {
    e.preventDefault();
    var targetDialog = $( e.currentTarget.getAttribute( 'data-modal' ) ).clone( true );

    if ( targetDialog[0] ) {
        open( {target: targetDialog} );
    }
};

var setContent = function ( content ) {
    $content.empty().append( content );
};

var close = function () {
    $modal.trigger( 'modal:beforeclose' );
    setTimeout( () => {
        // preserve modal content for reuse, this will preserve modals state upon previous open
        if ( preserve ) {
            $( $content.html() ).addClass( 'visually-hidden' ).appendTo( 'body' );
        }

        toggleOverlay( false );
        clear_content();
        $( window ).unbind( 'resize.modal' );

        $modal.trigger( 'modal:closecomplete' );
        preserve = false;
        isOpen = false;
    }, 200 );
};

function handleClose ( e ) {
    if ( [$modal[0], $overlay[0], $close[0]].indexOf( e.target ) > -1 ) {
        e.preventDefault();
        close();
    }
}

function clear_content () {
    $modal.hide();
    // patch: set iframe src to current location to clear out iframes flash video for IE,
    // otherwise video continues playing in background - simply removing the content from page doesn't work
    $content.css( {top: 'auto', left: 'auto'} ).find( 'iframe' ).attr( 'src', window.location.href );

    $content.empty();
    $overlay.fadeOut( 300 );
}

window.__modalinstance__ = null;
const Modal = {
    getInstance: function ( config ) {
        if ( !window.__modalinstance__ ) {
            return window.__modalinstance__ = this._init( config );
        }
        return window.__modalinstance__;
    },

    _init: function ( config ) {
        $overlay = $( '<div id="modal-overlay"></div>' );
        $modal = $( '<div id="modal"></div>' );
        $content = $( '<div id="modal-content" aria-role="dialog"></div>' );
        $close = $( '<span data-modal-close id="modal-close" class="modal-close" aria-role="button"></span>' );

        this.el = $modal;
        this.selector = '#modal';

        // set metadata
        $modal.data( 'origClassList', $modal.attr( 'class' ) );
        $modal.hide();
        $overlay.hide();
        $modal.append( $content, $close );

        this._init_events( config );

        $( document ).ready( function () {
            $( 'body' ).append( $overlay, $modal );
            $( document ).on( 'click', '[data-modal]', openTarget );
        } );

        return this;
    },

    _init_events: function () {
        $( document ).on( 'click', handleClose );
        $( window ).on( 'close.modal modal:close', this.close );
        this.el.on( 'click', '*[data-modal-close]', this.close );
    },

    center: center,
    open: open,
    close: close,
    setContent: content => {
        setContent( content );
        center();
    }
};

export default Modal;
