import Modal from '@plugins/modal';
import * as Media from '@util/MediaUtils';
import {lazyLoadImage} from '@util/ImageUtils';
import {getCookie, setCookie, COOKIES} from '@util/StorageUtils';
import gMap from '@modules/ui/find-a-dentist';
import Videoplayer from '@modules/ui/video';
import Header from '@modules/ui/header';
import Footer from '@modules/ui/Footer';
import Template from '@modules/ui/Template';
import ProductsService from '@services/CartService/ProductsService';
import NewsletterService from '@services/NewsletterService';
import dataLayer from './data-layer';
import copyTextToClipboard from '@util/copyTextToClipboard';

import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';

const {PAGE_CONTENT$} = upi.selector;

const Site = upi.namespace( 'Site' );
const Page = upi.namespace( 'Page' );

Object.extend( Page, {
    pathname: window.location.pathname.toLowerCase(),
    isInPath: function ( path ) {
        return this.pathname.indexOf( path ) > -1;
    }
} );

// Initialize Common page elements
Object.extend( Site, {
    _initializers: {},

    // prepend initializer to a specified namespaced component initalizer
    // useful when needing to initialize nested components such as a tabset within an accordion the needs to set panel height
    addInitializer ( namespace, fn ) {
        if ( typeof namespace === 'function' ) {
            fn = namespace;
            namespace = 'default';
        }

        this._initializers[namespace] = this._initializers[namespace] || [];
        this._initializers[namespace].push( fn );
    },

    callInitializers ( namespace ) {
        namespace = namespace || 'default';

        var initializers = this._initializers[namespace];

        if ( initializers && initializers.length ) {
            $.each( initializers, function ( i, fn ) {
                fn.call( this );
            }.bind( this ) );
        }
    },

    init () {
        this.patches();

        this.tracking.init();
        this.serviceWorker.init();
        this.navigation.init();
        this.messageBar.init();
        this.toothpastePopUp.init();
        this.frontPageNewsletterSignup();
        this.footerNewsletterSignup();
        this.forms();
        this.media.init();
        this.commonUI.init();
    },

    onLoad () {
        this.media.onLoad();
        this.commonUI.onLoad();
    },

    tracking: {
        init () {
            window.addEventListener( 'load', function () {
                switch ( window.location.pathname ) {
                    case '/':
                        return dataLayer.pageView.homepage();

                    case '/cart':
                        return dataLayer.pageView.cart();

                    case '/find-a-dentist':
                        return dataLayer.pageView.findADentist();

                    case '/Confirmation':
                        const $orderTotal = $( '.order-line--totals > .order-line-value' );
                        const orderTotalValue = $orderTotal.text() || '0';
                        const purchaseData = {
                            value: orderTotalValue.substr( 1 ),
                            currency: 'USD'
                        };
                        return dataLayer.pageView.purchaseComplete( purchaseData );
                }
            } )
        }
    },

    serviceWorker: {
        init () {
            if ( !navigator.serviceWorker ) {
                return;
            }

            if ( process.env.NODE_ENV === 'production' ) {
                navigator.serviceWorker.register( '/service-worker.js' ).then( reg => {
                    const {waiting, installing} = reg;
                    console.log( '[serviceWorker status]', {waiting, installing} );
                } )
            }
        }
    },

    navigation: {
        init () {
            Header.init();
            Footer.init( '#nav-lvl-footer' );

            if ( window.location.hash ) {
                scroll( 0, 0 );
                $( 'html, body' ).animate( {
                    scrollTop: $( window.location.hash ).offset().top - 86 + 'px'
                }, 600, 'swing' );
            }
        }
    },

    patches () {
        // patch: windows safari incomplete cssanimation support
        if ( /windows.*safari/i.test( navigator.userAgent ) ) {
            $( 'html' ).toggleClass( 'cssanimations', 'no-cssanimations' );
        }
    },

    toothpastePopUp: {
        init () {
            $( document ).on( 'click', '[data-buy-toothpaste]', this.handlePopUp.bind( this ) );
        },

        handlePopUp ( e ) {

            e.preventDefault();

            let button = $( e.currentTarget ),
                product = button.parents( '[data-item-id]' ),
                itemType = product.data( 'item-type' ),
                originalUrl = upi.i18n.getTextFromKey(
                    'amazon-link-original',
                    ''
                ),
                sensitivityUrl = upi.i18n.getTextFromKey(
                    'amazon-link-sensitivity',
                    ''
                ),
                getPopupHeadline = upi.i18n.getTextFromKey(
                    'toothpaste-popup-headline',
                    'You are now leaving Opalescence.com and being redirected to our Amazon store.'
                ),
                getPopupParagraph = upi.i18n.getTextFromKey(
                    'toothpaste-popup-paragraph',
                    'Be sure to only purchase Opalescence Whitening Toothpaste through the link below in order to ensure that your Opalescence toothpaste is authentic, fresh, and effective.'
                ),
                getPopupReturnBtn = upi.i18n.getTextFromKey(
                    'toothpaste-popup-return-btn',
                    'Return to<br />Opalescence.com'
                ),
                getPopupLinkBtn = upi.i18n.getTextFromKey(
                    'toothpaste-popup-link-btn',
                    'Continue to<br />Amazon'
                ),
                amazonId = itemType.toLowerCase() == "sensitivity" ? `${sensitivityUrl}` : `${originalUrl}`
                

                var modalPopUp = Template.create( require( 'templates/toothpastePopUp.html' ), { 
                    amazonUrl: `${amazonId}`,
                    popupHeadline: `${getPopupHeadline}`,
                    popupParagraph: `${getPopupParagraph}`,
                    popupReturnBtn: `${getPopupReturnBtn}`,
                    popupLinkBtn: `${getPopupLinkBtn}`

                } );

                Modal.getInstance().open( {
                    width: '75%',
                    content: modalPopUp
                } );
        }
    },

    messageBar: {
        init () {
            const self = this;
            const msgs = document.querySelectorAll(  upi.selector.SITE_MESSAGE_BAR );
            Array.from( msgs ).forEach( node => {
                const cookieName = node.dataset.msgName || 'site-msg';
                const hideNumDays = node.dataset.hideNumDays || 1;
                const isPersistent = node.classList.contains( 'notice' ) || !!hideNumDays;
                // cookieData = COOKIES.SITE_NOTICE_BAR; // defaults to upi_notice
                if ( self.isActive( node, cookieName, isPersistent ) ) {
                    self.show( node );
                    const closeBtn = node.querySelector( '[data-action="close"]' );
                    closeBtn.addEventListener( 'click', () => {
                        if ( isPersistent ) {
                            setCookie(
                                {
                                    // namespace message bar cookies
                                    cookie: COOKIES.SITE_NOTICE_BAR,
                                    key: {name: cookieName},
                                    value: 'disabled'
                                },
                                {expires: 60 * 24 * hideNumDays}
                            ); // disable for specified days OR 24 hours
                        }

                        self.hide( node );
                    } );
                }
            } );
        },
        show ( el ) {
            el.classList.remove( 'hidden' );
        },
        hide ( el ) {
            el.classList.add( 'hidden' );
        },
        isActive ( el, name, isPersistent ) {
            // check that the messageBar is on DOM and and a cookie has not been set for message (which means it's been read and closed)
            if ( isPersistent ) {
                var msgPattern = new RegExp( name + '~disabled', 'g' ),
                    upiNoticeCookie = getCookie(
                        COOKIES.SITE_NOTICE_BAR
                    );

                return (
                    typeof el !== 'undefined' && !msgPattern.test( upiNoticeCookie )
                );
            }

            return true;
        }
    },

    frontPageNewsletterSignup () {
        var self = this;
        var form = $( '#homePageNewsletterForm' );
        form.validate( {
            rules: {
                email: {
                    required: true,
                    email: true
                }
            },
            errorContainer: form.find( '#newsletter-form-msg' ),
            errorLabelContainer: form.find( '#newsletter-form-msg' ),
            submitHandler: function ( form, e ) {
                e.preventDefault();

                $( '.homePageNewsletterSubmit' ).addClass( 'processing' );

                var email = $( 'input.homePageNewsletterEmailAddress' ).val();

                console.log( 'email: ', email );

                self.newsletterSubmission( email, '#homePageNewsletterForm', '.homePageNewsletterSubmit' );
            }
        } );
    },

    footerNewsletterSignup () {
        var self = this;
        var form = $( '#footerNewsletterForm' );
        form.validate( {
            rules: {
                email: {
                    required: true,
                    email: true
                }
            },
            errorContainer: form.find( '#newsletter-form-msg' ),
            errorLabelContainer: form.find( '#newsletter-form-msg' ),
            submitHandler: function ( form, e ) {
                e.preventDefault();

                $( '.footerNewsletterSubmit' ).addClass( 'processing' );

                var email = $( 'input.footerNewsletterEmailAddress' ).val();

                self.newsletterSubmission( email, '#footerNewsletterForm', '.footerNewsletterSubmit' );
            }
        } );
    },

    newsletterSubmission ( email, form, button ) {

        NewsletterService.newsletterSignup( email )
            .then( ( res ) => {
                if ( res.status === 200 ) {
                    dataLayer.user.sendNewsletterRegistration();
                    $( form )
                        .html(
                            '<p class="text-green text-center text-lg">Thank you!<br/>We look forward to connecting!</p>' );
                }
            } )
            .then( () => {
                $( button ).removeClass( 'processing' );
            } )
            .catch( ( err ) => {
                $( form )
                    .find( '#newsletter-form-msg' )
                    .html( '<p class="text-red">Sorry there was an issue with submission, please try again.</p>' )
            } )
    },

    forms () {

        // initialize input masks through data-inputmask attr
        // usage: https://github.com/RobinHerbots/Inputmask#via-data-inputmask-attribute
        const maskableInputs = $( ':input[data-inputmask]' );
        upi.forms.inputmask.mask( maskableInputs );

        let $form = $( '#aspnetForm' ),
            $visibleFields;

        if ( $form[0] ) {
            $visibleFields = $( $form[0].elements ).filter( function ( el ) {
                return 'hidden' != el.type;
            } );

            // bind auto-clear
            $visibleFields.filter( '[data-autoclear]' ).on( 'blur', function () {
                this.value = '';
            } );
        }
    },

    media: {
        onLoad () {
            // lazy-load images
            $( '#page-content [data-lazy-src]' ).whenInView( lazyLoadImage, {context: upi.conf.SCROLL_CONTEXT} );
            const nodes = {};
            nodes.$siteContent = $( '#page-container' );

            Videoplayer.init( nodes.$siteContent );
        },
        init () {

            const handleImage404 = function ( e ) {
                const el = e.target;
                if ( el.nodeName.toLowerCase() === 'img' ) {
                    el.classList.add( 'img-broken' );
                }
            };
            // bind to event capture phase since 'error' events do not bubble
            document.body.addEventListener( 'error', handleImage404, true );

            $( `${PAGE_CONTENT$} a[href*="//www.youtube.com"]` ).on( 'click', function ( e ) {
                var target = e.currentTarget;

                // ignore direct links to the ultradent youtube channel
                if ( target.href && target.href.match( /\/ultradentproducts/i ) ) {
                    return true;
                }

                e.preventDefault();
                if ( Modernizr.mq( upi.mq.max['screenMedium'] ) ) {
                    // embed video to target
                    Media.embedMediaToTarget.call( target, true );
                }
                else {
                    Modal.getInstance().open( {
                        width: '75%',
                        content: Media.getMediaEmbedFor( target.href, true )
                    } );
                }
            } );
        }
    },

    commonUI: {
        onLoad: () => {
            const nodes = {};

            nodes.$siteContent = $( '#page-container' );
            nodes.$siteContent.corpus( 'slider' );
            nodes.$siteContent.corpus( 'carousel' );
            if ( Page.isInPath( 'find-a-dentist' ) || Page.isInPath( 'home' ) || location.pathname === '/' ) {
                gMap.init( nodes.$siteContent );
            }
            if ( Page.isInPath( 'opalescence-toothpaste' ) ) {
                if ( Modernizr.touchevents ) {
                    nodes.$siteContent.find( '.accordion-item' ).removeClass( 'accordion-item--active' );
                }
                else {
                    nodes.$siteContent.find( '.accordion-item' ).addClass( 'accordion-item--active' );
                }
            }
        },

        toothpasteModalShown: false,

        init () {
            // var $header = $( '#header-outer' );
            var $mainContent = $( PAGE_CONTENT$ );

            upi.Site.callInitializers( 'commonUI' );

            // initialize print buttons
            $( document ).on( 'click', '.button.print', function ( e ) {
                e.preventDefault();
                window.print();
            } );

            // general tooltips
            const getPopoverTemplate = ref => {
                const id = ref.getAttribute( 'data-tip-template' );
                const template = document.getElementById( id );
                return template.innerHTML;
            };
            // default tooltips
            tippy( '[data-tip-content]', {
                theme: 'light',
                touch: 'hold',
                content: ref => ref.getAttribute( 'data-tip-content' )
            } );

            $( document ).accordion();

            // bind auto-scrollers
            $( document ).on( 'click', 'a[data-auto-scroll]', function ( e ) {
                e.preventDefault();
                var $target = $( '#' + this.href.split( '#' )[1] );
                // only attach to <a href="#target">
                if ( !this.href || !$target[0] ) {
                    return;
                }
                $( $target ).scrollToElement();
            } );

            // restrict form input (with class"digits") to digits only
            $( document ).on(
                'keypress',
                '[contenteditable], input, textarea',
                upi.forms.restrict_input_values
            );

            // convert fields to custom that fall within the main content wrapper and hidden light boxes
            $( `${PAGE_CONTENT$}, .inline-lightbox` )
                .find( ':radio, :checkbox' )
                .toCustomFields();

            // add custom styling to form elements
            $mainContent
                .find( 'input[data-qty-select-toggle]' )
                .toggleQtySelect();

        }

    }
} );

$( document ).ready( function () {
    upi.Site.init();
} );

$( window ).on( 'load', function () {
    upi.Site.onLoad();
    window.scrollBy && window.scrollBy( 1, 0 );
} );
