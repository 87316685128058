import assign from 'object-assign';
import {googleAnalyticsLayer} from './data-layer-ga';
import {facebookPixelLayer} from './data-layer-fbq';

const dataLayer = upi.namespace( 'dataLayer' );
const dataLayerProto = {};

dataLayer.create = ( obj ) => assign( {}, dataLayerProto, obj );

export const user = dataLayer.create( {
    sendNewsletterRegistration() {
        facebookPixelLayer.newsletterRegistration();
    }
} );

export const cart = dataLayer.create( {

    sendItemsAdded( items = [], callback ) {
        googleAnalyticsLayer.itemsAdded( items, callback );
        facebookPixelLayer.itemsAdded( items );

        return this;
    },

    sendItemsRemoved( items = [], callback ) {
        googleAnalyticsLayer.itemsRemoved( items, callback );

        return this;
    }
} );

const product = dataLayer.create( {
    sendProductView: function ( item ) {
        googleAnalyticsLayer.productView( item );

        return this;
    }
} );

const pageView = dataLayer.create( {
    homepage() {
        facebookPixelLayer.viewHomepage();
    },
    cart() {
        facebookPixelLayer.viewCart();
    },
    payment() {
        facebookPixelLayer.viewPayment();
    },
    findADentist() {
        facebookPixelLayer.viewFindDentist();
    },
    purchaseComplete( data ) {
        facebookPixelLayer.purchaseComplete( data );
    }
} );

dataLayer.user = user;
dataLayer.product = product;
dataLayer.cart = cart;
dataLayer.pageView = pageView;

export default dataLayer;

