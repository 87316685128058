/*
// note: for future implementation for calculating image ratios
const ratios = {
    '1.3333': '3x4',
    '1.0000': '1x1',
    '0.7500': '4x3',
    '0.5625': '16x9'
};
*/

const sizeKeywords = [
    {type: 'highres@2x', width: 2400, quality: 70},
    {type: 'xlarge@2x', width: 1800, quality: 70},
    {type: 'large@2x', width: 1200, quality: 70},
    {type: 'medium@2x', width: 720, quality: 50},
    {type: 'small@2x', width: 360, quality: 50},
    {type: 'thumbnail@2x', width: 240, quality: 50},
    {type: 'highres', width: 1200, quality: 35},
    {type: 'xlarge', width: 900, quality: 50},
    {type: 'large', width: 600, quality: 65},
    {type: 'medium', width: 360, quality: 70},
    {type: 'small', width: 180, quality: 72},
    {type: 'thumbnail', width: 120, quality: 75}
];

export function getImagePresets( keyword ) {
    keyword += upi.env.IS_RETINA ? '@2x' : '';

    // defaults to thumnail preset
    return sizeKeywords.reduce( ( prev, curr ) => {
        return ( keyword === curr.type ) ? curr : prev;
    }, 'thumbnail' );
}

export function lazyLoadImage() {
    let $el = $( this ),
        $img = $( '<img />' ).attr( 'src', $el.data( 'lazy-src' ) );
    $img.insertBefore( $el );
    $img.attr( 'data-loaded', '' );
    $el.remove();
}

export function getImageSizeKeyword( w, h ) {
    const keywords = sizeKeywords;
    let keyword;

    for ( let i = 0; i < keywords.length; i++ ) {
        if ( keywords[i].width < w ) {
            keyword = keywords[i].type;
            break;
        }
    }

    return keyword || 'thumbnail';
}
