import ServiceProvider from '@services/serviceProvider';
import assign from 'object-assign';

const
    FIND_A_DENTIST = 'FindADentistAPI';

var provider = ServiceProvider.register(
    upi.paths.SVC_ORIGIN, {
        [FIND_A_DENTIST]: {method: 'get'}
    } );

export default assign( {}, provider, {
    getDentists ( userView ) {
        if ( !userView ) {
            this.throw( 'FindADentist.getDentists: userView is a required parameter' );
        }

        return this.request( FIND_A_DENTIST, userView );
    }
} );