import ServiceProvider from '@services/serviceProvider';
import assign from 'object-assign';

const NEWSLETTER = 'Newsletter';

var provider = ServiceProvider.register(
    upi.paths.SVC_ORIGIN, {
        [NEWSLETTER]: {method: 'get'}
    }
);

export default assign( {}, provider, {
    newsletterSignup ( email ) {
        if ( !email ) {
            this.throw( 'NewsletterService.newsletterSignup: email is a required parameter' );
        }

        return this.request( NEWSLETTER, {email} );
    }
} )