const PATTERN = '\\^(.*?)(\\||$)';

/**
 * Cookie Constants
 * These values provide both the cookie.name and RegExp pattern to use to extract the appropriate cookie value
 * Use with getCookie and parseCookieValue
 */
export const COOKIES = {
    // Cookie Names
    REGISTRATION: {
        name: 'upi_reg',
        TOKEN: {name: 'regToken'},
        // Cookie key=value pairs
        STATUS: {name: 'status'}
    },
    SITE_NOTICE_BAR: {
        name: 'upi_notice',

        SITE_MSG: {name: 'sitemsg'},
        DOWNTIME: {name: 'downtime'}
    },
    FIND_A_DENTIST: {
        name: 'find_a_dentist_prompt'
    },
    LAST_LOGIN: {name: 'LastLogin1'},
    CART_COUNT: {name: 'CartItemsCount1'},
    USER_GUID: {name: 'UserGuid1'},
    ORDER_GUID: {name: 'OrderGuid1'},
    TOOTHPASTE_PROMO: {name: 'ToothpastePromo'}
};

/**
 * @param cookie string returned from get_cookie
 * @param cookie.key
 * usage: parse_cookie_value( get_cookie( COOKIES.REGISTRATION ), COOKIES.REGISTRATION.STATUS )
 */
function parseCookieValue( cookieValue, param ) {
    var pattern = param.pattern || PATTERN,
        reCookie = new RegExp( param.name + pattern, 'g' );
    if ( reCookie.test( cookieValue ) ) {
        return unescape( RegExp.$1 );
    }
    return false;
}

/**
 * @param {Object} cookie key=pattern identifier
 * @param {String} existing cookies string
 * @type private
 */
function addKeyValue( param, cookieString ) {
    var keyArr = [],
        pattern = param.key.pattern || PATTERN,
        //pattern to sniff for key within current cookieString
        reKey = new RegExp( param.key.name + pattern );

    //if cookie has exisitng key values preserve them
    if ( cookieString ) {
        keyArr = cookieString.split( '|' );
    }

    //sniff for existing key=value pair and update it, otherwise create new key and append to existing value
    if ( keyArr.length > 0 && reKey.test( cookieString ) ) {
        for ( var i = 0; i < keyArr.length; i++ ) {
            var pair = keyArr[i].split( '~' );
            if ( pair[0] === param.key.name ) {
                pair[1] = param.value;
                keyArr[i] = pair.join( '~' );
                break;
            }
        }
    }
    else {
        keyArr.push( param.key.name + '~' + param.value );
    }
    return keyArr.join( '|' );
}

/********************************
 * EXPORTS
 */


export function hasLocalStorage() {
    try {
        localStorage.setItem( 'localtest', 'localtest' );
        localStorage.removeItem( 'localtest' );
        return true;
    }
    catch ( e ) {
        return false;
    }
}

export function hasSessionStorage() {
    try {
        sessionStorage.setItem( 'sessiontest', 'sessiontest' );
        sessionStorage.removeItem( 'sessiontest' );
        return true;
    }
    catch ( e ) {
        return false;
    }
}

/**
 * .setCookie
 * @param {Object} params
 * @param {Object} setting e.g. :expires, :domain, :path
 *
 * NOTE: must use interface defined by COOKIES for methods below
 * !!this method is only meant to work with cookies that are stored under concantenated set of values.
 * In order to save a single cookie value this method will need to be adjusted for this scenario
 */
export function setCookie( cookieData, settings ) {
    var hostname = location.hostname,
        subdomainPattern = /^.*?\./;
    //extend default params
    cookieData = cookieData || {};
    //{:cookie, :key, :value}
    settings = Object.extend( {
        value: '',
        expires: 60 * 24 * 365, // expiry set in minutes
        domain: subdomainPattern.test( hostname ) ? hostname.replace( subdomainPattern, '.' ) : '', // default .domain.com can be read from
        path: '/'
    }, settings );

    //convert expires to UTCString
    var exDate = new Date();
    exDate.setMinutes( exDate.getMinutes() + settings.expires );
    settings.expires = exDate.toUTCString();

    // if a cookie.key is provided store as key=value pairs, otherwise store as single value
    settings.value =
        (cookieData.key) ?
            addKeyValue( cookieData, getCookie( cookieData.cookie ) ) :
            cookieData.value;

    return writeCookie( cookieData.cookie, settings );
}

export function writeCookie( cookie, params = {} ) {
    const {name} = cookie,
        {domain, value, expires, path} = params;

    if ( name ) {
        document.cookie = `${name}=${value}; expires=${expires}; domain=${domain}; path=${path}`;
        return params;
    }

    return null;
}

/**
 * @param {Object} param [:name]
 * @returns {String} separated values
 * usage: get_cookie( COOKIES.REGISTRATION ) re
 */
export function getCookie( param ) {
    const reCookie = new RegExp( param.name + '=(.*?)(;|$)', 'g' );
    if ( reCookie.test( document.cookie ) ) {
        return unescape( RegExp.$1 );
    }
    return false;
}

export function deleteCookie( inName ) {
    if ( getCookie( inName ) ) {
        setCookie( inName, null, -(60 * 24 * 365) );
    }
}
