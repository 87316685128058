import assign from 'object-assign';

let _instance = null;
let _translationMap = null;

function i18nFactory () {
    if ( !_instance ) {
        _instance = {
            setTranslationMap ( translations ) {
                if ( translations.length > 0 ) {
                    _translationMap = new Map();
                    translations.forEach( ( {key, value} ) => _translationMap.set( key, value ) );
                }

                return _translationMap;
            },

            getTextFromKey ( key, fallback = '' ) {
                return (_translationMap && _translationMap.get( key )) || fallback;
            }
        }
    }

    return _instance;
}

// Note: expose a global namespace as Singleton instance for retrieving translations from any view
export const i18n = assign( upi.namespace( 'i18n' ), i18nFactory() );

// initialize translations from the upi.i18n.translations global
// this is expected to be set by hte server by loading a js file from `/js/i18n/translations/{LOCALE}.js`
if ( i18n.translations ) {
    console.log('SET')
    i18n.setTranslationMap( i18n.translations );
}
